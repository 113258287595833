import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import SVG from "./background.svg";
import Defaultbanner from "./Defaultbanner.png"
import MediaQuery from "react-responsive";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useGlobal } from "../../../store/index";
import {setItem} from "utils/localStorage"

import API_KEY from "../../../api";

const useProgressiveImg = (lowQualitySrc, highQualitySrc) => {
  const [src, setSrc] = useState(lowQualitySrc);
  useEffect(() => {
    setSrc(lowQualitySrc);
    const img = new Image();
    img.src = highQualitySrc;
    img.onload = () => {
      setSrc(highQualitySrc);
    };
  }, [lowQualitySrc, highQualitySrc]);

  return [src, { blur: src === lowQualitySrc }];
};

export default function HeaderImageComponent() {
  const params = useParams();
  const searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString);
  const history = useHistory();

  setItem("collection_link", params?.id);
  const [globalState, setGlobalState] = useGlobal();

  const { collection_link } = globalState;
  const [collectionDetails, setCollectionDetails] = useState(null);

  useEffect(() => {
    const searchString = window.location.search;

    if (urlParams.get("type") === "PREVIEW") {
      setItem("type", "PREVIEW");
    }
    API_KEY.URL.get(API_KEY.path.collections, {
      params: {
        "filter[collection_link][_eq]": params?.id,
        fields: "*",
      },
    }).then((res) => {
      console.log("Collection_data: ", res);
      setCollectionDetails(res?.data?.data?.length ? res?.data?.data[0] : "");
      setItem(
        "collection_details",
        res?.data?.data?.length ? JSON.stringify(res?.data?.data[0]) : ""
      );
    });
  }, [collection_link, params]);

  const tinyImage = `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.banner_image}?quality=10`;
  const largeImage = collectionDetails?.banner_image
    ? `${process.env.REACT_APP_DIRECTUS}assets/${collectionDetails?.banner_image}?quality=100`
    : Defaultbanner;

  //const tinyImage = `${process.env.REACT_APP_CLOUDINARY}/q_100/${collectionDetails?.banner_image}`;
  //const largeImage = `${process.env.REACT_APP_CLOUDINARY}/q_100/${collectionDetails?.banner_image}`;

  const [src, { blur }] = useProgressiveImg(tinyImage, largeImage);

  return (
    <>
      <Row  style={{zIndex:0, width: "100%" }} >
        <Col
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
        >
          
            <Row
              className={blur ? "box animate" : ""}
              style={{
                height:"100%",
                width: "100%",
                //height: "11.5em",
                borderRadius: "8px",
                marginBottom: "1em",
                //paddingRight:"4.2em",
                paddingTop:"0.8em",

                transition: "filter 0.8s ease-out",
              }}
            >
              <img
                src={largeImage}
                style={{
                  width: "100%",
                  height:"100%",
                  //height: "11.5em",
                  display: "block",
                  borderRadius: "8px",
                  filter: blur ? "blur(20px)" : "none",
                  transition: blur ? "none" : "filter 0.5s ease-out",
                  
                }}
              />
            </Row>
          

          
        </Col>
      </Row>
    </>
  );
}
