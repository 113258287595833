import React from "react";
import { Typography, Row, Col, Card, Space, Image, Tooltip } from "antd";
import { MdDelete } from "react-icons/md";
import ImageRouter from "react-common-components/ImageRouter";
const { Title, Text } = Typography;

export default function ProductCard({
  product,
  index,
  handleCardClick,
  handleDelete,
}) {
  const findPriceAttribute = (transformedAttributes, keyword) => {
    var attributes = Object.keys(transformedAttributes);
    var index = attributes.findIndex((element) =>
      element.toLowerCase().includes(keyword)
    );
    if (index != -1) {
      return transformedAttributes?.[attributes[index]]?.split(" ") || [];
    } else {
      return [];
    }
  };
  const getPrice = (transformedAttributes) => {
    var price = findPriceAttribute(transformedAttributes, "price");
    if (price.length > 2) {
      return price;
    } else {
      return findPriceAttribute(transformedAttributes, "cost");
    }
  };
  var price = getPrice(product.transformedAttributes);
  return (
    <Col key={index} xs={24} sm={24} md={12} lg={6} xl={6}>
      <Card
        key={index}
        className="product-card"
        cover={
          <Image
            // onClick={() => handleCardClick(product)}
            alt="product name"
            src={`${process.env.REACT_APP_CLOUDINARY}/b_rgb:f2f2f2,c_fit,h_214,q_60,w_283/${product?.product_images[0]?.directus_files_id}`}
            preview={false}
            fallback={
              product?.product_images[0]
                ? `${process.env.REACT_APP_DIRECTUS}assets/${product?.product_images[0]?.directus_files_id}?transforms=[["resize",{"width":283,"height":214,"fit":"contain","background":{"r":242,"g":242,"b":242}}]]`
                : "http://placehold.jp/24/0a8f8d/ffffff/283x214.png?text=Product Image"
            }
            preview={false}
          />
        }
      >
        <Tooltip placement="topLeft" title={"Remove"}>
          <MdDelete
            size={30}
            onClick={() => handleDelete(product)}
            className="delete-shortlist"
          />
        </Tooltip>

        <Row style={{ minHeight: "3.7rem" }}>
          <Space direction="vertical">
            <Title className="product-id" level={5} style={{ paddingTop: 0 }}>
              {product?.products_id ? `ID: ${product?.products_id}` : ""}
            </Title>

            {price.length > 2 ? (
              <Row>
                <Space direction="horizontal" size="small">
                  <Title
                    className="product-price"
                    style={{ fontFamily: "Gilroy", paddingTop: 0 }}
                  >
                    {price[0] + " " + price[1] + " "}
                  </Title>
                  <Text type="secondary">{" " + price.slice(2).join(" ")}</Text>
                </Space>
              </Row>
            ) : null}
          </Space>
        </Row>
        {/* <Row>
          <Text className="product-id" type="secondary">
            ID :{" "}
            {item?.products_id?.product_id
              ? item?.products_id?.product_id
              : "No product id"}
          </Text>
        </Row>
        <Row>
          {item?.show_price && (
            <Title className="product-price" style={{ fontFamily: "Gilroy" }}>
              {item?.custom_price_value
                ? item?.custom_price_value
                : " "}
            </Title>
          )}
        </Row>
        <Divider type="horizontal" />
        <Row>
          {item?.show_moq && (
            <Title className="product-price" style={{ fontFamily: "Gilroy" }}>
              {item?.custom_moq ? item?.custom_moq : " "}
            </Title>
          )}
        </Row>
        <Row>
          {item?.show_sampling_lead_time && (
            <Title className="product-price" style={{ fontFamily: "Gilroy" }}>
              {item?.custom_sampling_lead_time
                ? item?.custom_sampling_lead_time
                : " "}
            </Title>
          )}
        </Row> */}
      </Card>
    </Col>
  );
}
