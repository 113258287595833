import React, { useEffect, useState } from "react";
import Viewer from "./Viewer";
import {Row,Col} from "antd"

export default function Index({ images }) {
  const [urls, setUrls] = useState([]);
  useEffect(() => {
    setUrls(
      images.map(
        (image) =>
        `${process.env.REACT_APP_CLOUDINARY}/b_rgb:f2f2f2,c_pad,h_1200,q_80,w_1800/${image?.directus_files_id?.id}`
        // `${process.env.REACT_APP_DIRECTUS}assets/${image?.directus_files_id?.id}?fit=contain&width=1800&height=1200&quality=80`
      )
    );
  }, [images]);
  return <Row justify="center">
   
   <Col >
   {urls.length > 0 && <Viewer images={urls} />}
   </Col>
   
  </Row>;
}

