import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Typography,
  Input,
  Button,
  message,
  Modal,
  Image,
  Alert,
  Carousel,
} from "antd";
import API_KEY from "../../../api";
import QUOTATION_KEY from "api/quotation";
import "./publicqr.less";
import TopBar from "./TopBar";
import carouseNext from "../../../assets/images/carouseNext.png";
import carousePrev from "../../../assets/images/carousePrev.png";
import ImageRouter from "react-common-components/ImageRouter";

const { Text } = Typography;

function CataloguesView({ catalogueData, setCatalogueData, catalogueClick}) {
  return (
    <div>
      {catalogueData && catalogueData?.length > 0 && (
        <>
          <Text className="cataloguesTxt">See our collections</Text>
          <Row>
          <Text className="cataloguesTxtSmall">{catalogueData?.length}{' '}catalogues</Text>
          </Row>
        </>
      )}
      <div className="catalogueDiv">
        {catalogueData &&
          catalogueData?.length > 0 &&
          catalogueData.map((item) => {
            return (
              <div 
              onClick={() => catalogueClick(item)}
              className="catalogueDiv_catalogueCard">
                <Row>
                  <Text className={`catalogueDiv_catalogueCard_txtLarge`}>
                    {item?.name.length > 34 ? item?.name.substring(0,34)+'...' : item?.name}
                  </Text>
                </Row>
                <Text className="catalogueDiv_catalogueCard_txtSmall">
                  {item?.product_count}{" "}
                  {item?.product_count === 1 ? "product" : "products"}
                </Text>
                <div className={`catalogueDiv_catalogueCard_imageDiv`}>
                  {item?.images &&
                    item?.images.length > 0 &&
                    item?.images.map((i, index) => {
                      return (
                        <>
                          {index < 4 && (
                            <ImageRouter    
                            timeout={1500}
                            preview={false}          
                            className="catalogueDiv_catalogueCard_imageDiv_img"
                            src={`${process.env.REACT_APP_CLOUDINARY}/q_auto,f_auto/${i?.directus_files_id?.id}`}
                            alt="Catalogue images"
                            fallback={`${process.env.REACT_APP_DIRECTUS}assets/${i?.directus_files_id?.id}`}
                          />
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default CataloguesView;
