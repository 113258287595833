import React, { useState, useEffect } from 'react';
import OnImagesLoaded from 'react-on-images-loaded';
import { Image } from 'antd';

export default function ImageRouter(props) {
  const [imageURL, setImageURL] = useState(props.src);
  const start = Date.now();
  const timeout = props.timeout ? props.timeout : 1000;
  useEffect(() => {
    setImageURL(props.src);
  }, [props.src]);
  return (
    <OnImagesLoaded
      onLoaded={() => {
        // const end = Date.now();
        // const elapsed = end - start;
        // console.log("Image Load time:",elapsed);
        if (props.imageLoaded) {
          props.imageLoaded();
        }
        return null;
      }}
      onTimeout={() => {
        // console.log("Image is fallbacked:", props.fallback)
        setImageURL(props.fallback);
        return null;
      }}
      timeout={timeout}>
      <Image
        {...props}
        fallback={
          props.secondFallbackUrl ? props.secondFallbackUrl : props.fallback
        }
        src={imageURL}
      />
    </OnImagesLoaded>
  );
}
