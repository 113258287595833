import React, { useCallback, useEffect, useState, useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import {
  Button,
  Row,
  Col,
  Divider,
  notification,
  Typography,
  Card,
  Breadcrumb,
} from "antd";
import {
  customRenderItem,
  customRenderThumb,
  ImageArrowNext,
  ImageArrowPrev,
  renderAttributeData,
} from './Utils';
import { isValidUrl } from 'utils';
import { sendAnalyticsEvent } from 'utils/sendAnalyticsEvent';
import { useHistory, useParams, Link } from 'react-router-dom';
import API_KEY from '../../api';
import ImageRouter from 'react-common-components/ImageRouter';
import ImageWithMagnification from 'react-common-components/ImageWithMagnification';
import { Colors } from 'theme';
import { useGlobal } from '../../store/index';
import StickyBox from 'react-sticky-box';
import { QuoteModal, WishlistModal } from './Modals';
import MediaQuery from 'react-responsive';
import axios from 'axios';
//assets
import arrow from "../../assets/images/arrow.svg";
import close from "../../assets/images/closeFill.svg";
import leftArrow from "../../assets/images/leftArrow.svg";
import rightArrow from "../../assets/images/rightArrow.svg";
import downArrow from "../../assets/images/downArrow.svg";
import quote from "../../assets/images/quote.svg";
import quote2 from "../../assets/images/quote2.svg";
import wishlists from "../../assets/images/wishlists.svg";
import ShortlistOutline from "../../assets/images/ShortlistOutline.svg";
import ShortlistBlip from "../../assets/images/ShortlistBlip.svg";
import ShortlistLightGreen from "../../assets/images/ShortlistLightGreen.svg";
import { useLocation } from "react-router-dom";
import "./style.css";
import { DEFAULT_IMAGE_QUALITY } from "constants/index";
const { Text } = Typography;
export default function ProductSlide({
  collectionLink,
  allProducts,
  actions,
  checkedProducts,
  productInd,
  indexOnPage,
  nbHits,
  updateAllProducts,
  handleRightClick,
  handleLeftClick,
  updateIndex,
  imageQuality
}) {
  const [globalState, setGlobalState] = useGlobal();
  const history = useHistory();
  const params = useParams();
  const searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString);
  const [collectionDetails, setCollectionDetails] = useState(null);
  const scrollRef = useRef();
  const topRef = useRef();
  const [dismissWishlists, setDismissWishlists] = useState(true);
  const [productIndex, setProductIndex] = useState(productInd);
  const [indexRel, setIndexRel] = useState(indexOnPage);
  const [quoteModal, setQuoteModal] = useState(false);
  const [wishlistModal, setWishlistModal] = useState(false);
  const [wishlisted, setWishlisted] = useState(false);
  const [quoted, setQuoted] = useState(false);
  const [id, setId] = useState(null);
  const [modalStatus, setModalStatus] = useState(false);
  const deviceType = globalState.deviceType;
  const [showFull, setShowFull] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [validProductPills, setValidProductPills] = useState({});
  const [variantMetadata, setAllVariantData] = useState({});
  const [sellingPriceAttr, setSellingPriceAttr] = useState(null);
  const [hingeAttributes, setHingeAttributes] = useState([]);
  const [currentProductValues, setCurrentProductValues] = useState(
    allProducts[indexRel]?.variant_meta?.variant_data_map?.filter(
      (item) => item.variant_id === allProducts[indexRel]?.id
    )
  );
  const [variantData, setVariantData] = useState("");
  const [product, setProduct] = useState(allProducts[indexRel] || {});
  const [hinge, setHinge] = useState([]);
  const [hingeWithKeyValue, setHingeWithKeyValue] = useState({});
  let carousel = useRef(null);
  useEffect(() => {
    setProductIndex(productInd);
    // window.scrollY=0;
    sendAnalyticsEvent("open_product_modal", {
      product_id: allProducts[indexOnPage].id,
    });
  }, [productInd]);

  useEffect(() => {
      const timer = setInterval(view, 5000);
      return () => clearInterval(timer);
  }, []);

  const view = async () => {
    const collection = localStorage.getItem('collection_link')
    const sessionId = localStorage.getItem(collection)
    // this call identifies the session to check if the session is live
    await axios.post(
      `${process.env.REACT_APP_API_SERVICE}analytics/session/view/?sessionId=${sessionId}&identifier=${collection}`
    );  
  };

  useEffect(() => {
    setIndexRel(indexOnPage);
    setProduct(allProducts[indexOnPage]);
    if (carousel && carousel?.state?.selectedItem > 0) {
      carousel.state.selectedItem = 0;
    }
    setImageLoaded(false);
    setQuoted(false);
  }, [indexOnPage]);

  useEffect(() => {
    if (urlParams.get("type") === "PREVIEW") {
    }
    API_KEY.URL.get(API_KEY.path.collections, {
      params: {
        "filter[collection_link][_eq]": params?.id,
        fields: "*",
      },
    }).then((res) => {
      setCollectionDetails(res?.data?.data?.length ? res?.data?.data[0] : "");
      setId(res?.data?.data[0]?.id);
    });
  }, [params?.id]);


  // creating variant data tree using variant data map
  useEffect(() => {
    const currentProductData = allProducts[indexRel];
    setAllVariantData(currentProductData?.variant_meta);
    const _sellingPriceAttrObj =
      (currentProductData?.attributes || []).find(
        (attr) =>
          attr.attribute_values_id.attribute_id.internal_name ===
          "base_selling_price"
      ) || {};

    const sellingPriceAttr =
      _sellingPriceAttrObj?.attribute_values_id?.attribute_id?.id || "";

    setSellingPriceAttr(sellingPriceAttr);

    const _hingesAttributes =
      currentProductData?.variant_meta?.hinge_attributes?.sort(
        (a, b) => b.priority - a.priority
      );

    setHingeAttributes(
      _hingesAttributes.filter((item) => item.id !== sellingPriceAttr)
    );

    const hinges = {};
    const priorityHingeIdArr = [];

    for (let i = 0; i < _hingesAttributes?.length; i++) {
      const id = _hingesAttributes[i]?.id;
      if (id !== sellingPriceAttr) {
        priorityHingeIdArr.push(id);
        const name = _hingesAttributes[i]?.name;
        hinges[id] = name;
      }
    }

    const currentProductID = currentProductData.id;
    const currentProduct = allProducts[
      indexRel
    ].variant_meta.variant_data_map.find(
      (item) => item.variant_id === currentProductID
    );

    const { availableOptions, selectedVariant } = getDataAndSelection(
      currentProductData?.variant_meta.variant_data_map,
      currentProduct || currentProductData,
      currentProductData?.variant_meta.hinges_value_map,
      priorityHingeIdArr[0],
      priorityHingeIdArr,
      true
    );

    setCurrentProductValues(selectedVariant);
    setValidProductPills(availableOptions);
    setHingeWithKeyValue(hinges);
    setHinge(priorityHingeIdArr);
  }, [indexRel]);

  const onClickCard = useCallback(
    (product, isShortlist, metaData, price, sellingPriceAttr) => {
      if (isShortlist) {
        setWishlistModal(true);
        actions.addProduct(
          product,
          product.id,
          metaData,
          price,
          sellingPriceAttr
        );
        sendAnalyticsEvent("shortlist_product", { product_id: product?.id });
      } else {
        actions.removeProduct(product, product.id);
        sendAnalyticsEvent("remove_from_shortlist", {
          product_id: product?.id,
        });
      }
    },
    [actions]
  );

  const handleQuery = () => {
    if (urlParams.toString() === "type=PREVIEW") {
      notification.info({
        message:
          "You cannot perform this action because you are browsing in preview mode",
        icon: null,
      });
    } else {
      history.push({
        pathname: params?.expname
          ? `/send-query/${params?.expname}/${params?.id}/${collectionDetails?.exporter_name}`
          : `/send-query/${params?.id}/${collectionDetails?.exporter_name}`,
        search: urlParams.toString(),
      });
    }
    sendAnalyticsEvent("send_query_button", {
      collection_details: collectionDetails?.exporter_name,
    });
  };

  const handleGridView = () => {
    history.push({
      pathname: params?.expname
        ? `/${params?.expname}/${collectionLink}`
        : `/${collectionLink}`,
      search: urlParams.toString(),
    });
    sendAnalyticsEvent("show_grid_view", { collection_link: collectionLink });
  };

  const [imgLoaded, setImgLoaded] = useState(false);

  const viewProducts = allProducts
    .slice(0, 5)
    .map((_prod, ind) => {
      return { ..._prod, index: ind };
    })
    .filter((product) => product?.id !== allProducts[indexOnPage]?.id)
    .slice(0, 4);

  const handleScroll = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleRight = () => {
    setImgLoaded(false);
    handleRightClick();
  };

  const handleLeft = () => {
    setImgLoaded(false);
    handleLeftClick();
  };

  const handleCardClick = (ind) => {
    updateIndex(ind, ind);
  };

  const getPrice = (attributes = []) => {
    const priceAttribute =
      attributes.find(
        (attr) =>
          attr.attribute_values_id.attribute_id.internal_name ===
          "base_selling_price"
      ) || null;

    if (!priceAttribute) return [];

    const { composite = {}, values } =
      priceAttribute?.attribute_values_id || {};
    const { unit = "", currency = "" } = composite;

    return [currency, values, unit];
  };

  const handleKeyPress = (e) => {
    e.preventDefault();
    console.log(e.keyCode);
  };

  const pillsData = allProducts[indexRel];

  const pills = pillsData?.variant_meta?.hinges_value_map;

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_SERVICE}items/v2/collection_products/${params.id}`,
        {
          page: 1,
          ...globalState.filters,
          records_per_page: globalState.pageSize,
          type: "variant",
          parent_id: pillsData?.parent_id,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setVariantData(res?.data?.data?.hits);
        }
      });
  }, [indexRel]);

  const handleVariantChange = (attributeId, pillValue, pillsData) => {
    if (currentProductValues[0][attributeId] === pillValue) {
      return;
    }

    setImgLoaded(false);

    const newPillsSelection = {
      ...currentProductValues[0],
      [attributeId]: pillValue,
    };

    const { selectedVal, availableOptions, selectedVariant } =
      getDataAndSelection(
        variantMetadata.variant_data_map,
        newPillsSelection,
        variantMetadata.hinges_value_map,
        attributeId,
        hinge,
        false
      );

    const productToShow = selectedVariant[0];
    setValidProductPills(availableOptions);
    setCurrentProductValues([
      { ...selectedVal, variant_id: productToShow.variant_id },
    ]);

    setProduct({
      ...variantData?.filter(
        (_j) => _j.id.toString() === productToShow.variant_id.toString()
      )?.[0],
    });
  };

  function onlyUnique(arr) {
    var _tempArr = [];
    arr.forEach((_arr, index) => {
      if (_tempArr.indexOf(_arr) < 0) {
        _tempArr.push(_arr);
      }
    });
    return _tempArr;
  }

  function getDataAndSelection(
    variant_map,
    selectedVal,
    variants,
    _key,
    _orderedHinges,

    isCreate
  ) {
    const _variants = JSON.parse(JSON.stringify(variants));
    var _keys = _orderedHinges;
    var newArr = variant_map;
    var _keyIndex = _keys?.indexOf(_key);
    _keys?.forEach((key, index) => {
      if (index >= _keyIndex && index > 0) {
        if (
          !newArr?.filter((item) => item?.[key] === selectedVal?.[key])?.length
        ) {
          selectedVal[key] = newArr?.[0]?.[key];
        }
        _variants[key] = newArr?.map((v) => v?.[key]);
        _variants[key] = onlyUnique(_variants[key]);
        newArr = newArr?.filter((v) => v?.[key] === selectedVal?.[key]);
      } else {
        if (Object.keys(validProductPills)?.length && !isCreate) {
          _variants[key] = [...validProductPills?.[key]];
        }
        newArr = newArr?.filter((v) => v?.[key] === selectedVal?.[key]);
      }
    });

    return {
      selectedVal: selectedVal,
      availableOptions: _variants,
      selectedVariant: newArr,
    };
  }

  const price = getPrice(product?.attributes);
  const sortedValues = product?.attributes
    .sort(
      (a, b) =>
        b.attribute_values_id.attribute_id.priority -
        a.attribute_values_id.attribute_id.priority
    )
    .filter(
      (attribute) =>
        !hinge.includes(attribute.attribute_values_id.attribute_id.id) &&
        sellingPriceAttr !== attribute.attribute_values_id.attribute_id.id
    );
  const renderValues = sortedValues.reduce((agg, attribute) => {
    return [
      ...agg,
      {
        key: attribute.attribute_values_id.attribute_id.name,
        value: renderAttributeData(attribute?.attribute_values_id, attribute?.attribute_values_id?.attribute_id?.dtype),
      },
    ];
  }, []);

  return (
    <>
      <QuoteModal
        quoteModal={quoteModal}
        setQuoteModal={setQuoteModal}
        setQuoted={setQuoted}
        // product={allProducts[indexRel]}
        product={product}
        variantMeta={variantMetadata}
        id={id}
        setModalStatus={setModalStatus}
      />
      <WishlistModal
        wishlistModal={wishlistModal}
        setWishlistModal={setWishlistModal}
        // product={allProducts[indexRel]}
        product={product}
        setWishlisted={setWishlisted}
      />
      <Row
        className="scroll"
        onKeyDown={(e) => {
          handleKeyPress(e);
        }}
      >
        <Col sm={24} xs={24} md={24} lg={24} xl={24}>
          <StickyBox className="sticky-container">
            <Row className="header">
              <Col xs={20} sm={22} md={22} lg={22} xl={21}>
                <Text
                  className="t2 cursor"
                  ellipsis
                  onClick={() => handleGridView()}
                >
                  <span ref={topRef}>
                    <img
                      className="go-back"
                      src={leftArrow}
                      alt="Go back"
                      width={25}
                    />
                  </span>
                  Back
                </Text>
              </Col>
              <Col xs={4} sm={2} md={2} lg={2} xl={3}>
                <Row
                  classname="t5 cursor"
                  onClick={() => handleQuery()}
                  justify="end"
                  style={{ marginTop: "0.3rem", cursor: "pointer" }}
                >
                  <span
                    disabled={!checkedProducts.length}
                    className={`flex`}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      className="img wishlistIcon"
                      src={
                        checkedProducts.length > 0
                          ? ShortlistBlip
                          : ShortlistOutline
                      }
                      alt="wishlist"
                      width={deviceType === "mobile" ? 24 : 18}
                      height={deviceType === "mobile" ? 24 : 18}
                    />
                    {/* <MediaQuery minWidth={600}> */}
                    {deviceType !== "mobile" && (
                      <span>
                        {checkedProducts.length
                          ? ` My Shortlist (${checkedProducts.length})`
                          : ` My Shortlist`}
                      </span>
                    )}
                    {/* </MediaQuery> */}
                    {/* <MediaQuery maxWidth={500}> */}
                    {deviceType === "mobile" && (
                      <span style={{ fontSize: "16px" }}>
                        {checkedProducts.length
                          ? `  (${checkedProducts.length})`
                          : ` `}
                      </span>
                    )}
                    {/* </MediaQuery> */}
                  </span>
                </Row>
              </Col>
            </Row>
          </StickyBox>
          <Divider style={{ margin: "0" }} />

          <Row className="breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link onClick={() => handleGridView()}>Catalogue</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={{}}>Product</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>

          <Row gutter={8} className="image-container">
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
              <Carousel
                // key={`${allProducts[indexRel]?.product_images?.[0].directus_files_id}${indexRel}`}
                key={`${product?.product_images?.[0].directus_files_id}${indexRel}${currentProductValues?.[0]?.variant_id}`}
                ref={(el) => (carousel = el)}
                transitionTime={300}
                infiniteLoop={
                  // allProducts[indexRel]?.product_images.length > 1
                  product?.product_images?.length > 1 ? true : false
                }
                renderArrowNext={ImageArrowNext}
                renderArrowPrev={ImageArrowPrev}
                showStatus={false}
                showThumbs={
                  (window.innerWidth > 768) & (deviceType !== "mobile")
                    ? true
                    : false
                }
                renderItem={customRenderItem}
                renderThumbs={customRenderThumb}
                thumbWidth={100}
                showIndicators={
                  // allProducts[indexRel]?.product_images.length > 1
                  product?.product_images?.length > 1 ? true : false
                }
                width={"100%"}
                selectedItem={0}
              >
                {/* {allProducts[indexRel]?.product_images?.map((val, ind) => { */}
                {product?.product_images?.map((val, ind) => {
                  const defaultImageQualityMagnified = DEFAULT_IMAGE_QUALITY?.MAGNIFIED || {}
                  const defaultImageQualityCorousel = DEFAULT_IMAGE_QUALITY?.COROUSEL || {}
                  const { h: height_magnified = defaultImageQualityMagnified?.h, q: quality_magnified = defaultImageQualityMagnified?.q, w: width_magnified = defaultImageQualityMagnified?.w } = imageQuality.image_quality_magnify?.high || {};
                  const { h: height_carousel = defaultImageQualityCorousel?.h, q: quality_carousel = defaultImageQualityCorousel?.q, w: width_carousel = defaultImageQualityCorousel?.w} = imageQuality.image_quality_carousel?.high || {};

                  const MagnifiedSrc = val?.directus_files_id
                    ? `${process.env.REACT_APP_CLOUDINARY}/b_rgb:f2f2f2,c_pad,h_${height_magnified},q_${quality_magnified},w_${width_magnified}/${val?.directus_files_id}`
                    : `${process.env.REACT_APP_DIRECTUS}assets/${val?.directus_files_id}?transforms=[["resize",{"width":${width_magnified},"height":${height_magnified},"fit":"contain","background":{"r":255,"g":255,"b":255}}]]`;
                  const imagesrc = `${
                    process.env.REACT_APP_CLOUDINARY
                  }/b_rgb:ffffff,f_auto,c_pad,h_${
                    deviceType === "mobile" ? "900" : "600"
                  },q_100,w_900/${val?.directus_files_id}`;
                  const fallbackimagesrc =
                    deviceType === "mobile"
                      ? `${process.env.REACT_APP_DIRECTUS}assets/${val?.directus_files_id}?transforms=[["resize",{"width":900,"height":900,"fit":"contain","background":{"r":255,"g":255,"b":255}}]]`
                      : `${process.env.REACT_APP_DIRECTUS}assets/${val?.directus_files_id}?transforms=[["resize",{"width":900,"height":600,"fit":"contain","background":{"r":255,"g":255,"b":255}}]]`;

                  if (
                    collectionDetails?.manufacturer !== 324 &&
                    deviceType !== "mobile"
                    //  && window.innerWidth > 768
                  ) {
                    return (
                      <ImageWithMagnification
                        magnifiedSrc={MagnifiedSrc}
                        src={imagesrc}
                        fallbackUrl={fallbackimagesrc}
                        item={val}
                        imgLoaded={imgLoaded}
                        setImgLoaded={setImgLoaded}
                        key={`${val?.directus_files_id}${currentProductValues?.[0]?.variant_id}`}
                        environment="buyer"
                      />
                    );
                  }
                  return (
                    <Row key={val?.["directus_files_id"]}>
                      <ImageRouter
                        className={`object-center ${
                          deviceType === "mobile" ? "mobileContainerImg" : ""
                        }`}
                        src={imagesrc}
                        imgLoaded={() => setImgLoaded(true)}
                        alt={"prod_corousal"}
                        fallback={fallbackimagesrc}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = fallbackimagesrc;
                        }}
                        timeout={2500}
                        key={`${val?.directus_files_id}${currentProductValues?.[0]?.variant_id}`}
                      />
                    </Row>
                  );
                })}
              </Carousel>
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} xl={{ offset: 2, span: 8 }}>
              <Row className="id-bar">
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  <img
                    src={leftArrow}
                    alt="left"
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLeft()}
                  />
                </Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20} align="center">
                  <Text className="t7">
                    {product?.products_id &&
                      "ID: " + product?.products_id?.toString()}
                    {/* {allProducts[indexRel]?.products_id &&
                      "ID: " + allProducts[indexRel]?.products_id?.toString()} */}
                  </Text>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2} align="right">
                  <img
                    src={rightArrow}
                    alt="right"
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRight()}
                  />{" "}
                </Col>
              </Row>
              <Row align="center">
                <Text className="t8" style={{ color: Colors?.midGray }}>
                  Showing {productIndex + 1} / {nbHits} products
                </Text>
              </Row>
              <Divider style={{ margin: "0.5rem 0" }} />
              {price.length > 0 ? (
                <Row>
                  <Text className="t7 price">
                    {/* ₹ */}
                    {price[0]} {price[1]}
                    <span className="t6"> {price[2]}</span>
                  </Text>
                </Row>
              ) : null}

              <Row className="modal-btns">
                <Col span={12}>
                  <Button
                    className="t5 wishlist-btn"
                    type="outline"
                    size="large"
                    onClick={() => {
                      onClickCard(
                        product,
                        !checkedProducts?.some(
                          (prod) => prod?.id === product?.id
                        ),
                        variantMetadata,
                        price,
                        sellingPriceAttr
                      );
                    }}
                    // onClick={() => {
                    //   onClickCard(
                    //     allProducts[indexRel],
                    //     !checkedProducts?.some(
                    //       (prod) => prod?.id === allProducts[indexRel]?.id
                    //     )
                    //   );
                    // }}
                    style={{
                      color: Colors?.gray1,
                      border: `${
                        checkedProducts?.some(
                          (prod) => prod?.id === product?.id
                          // (prod) => prod?.id === allProducts[indexRel]?.id
                        )
                          ? "none"
                          : "1px solid"
                      }`,
                      background: `${
                        checkedProducts?.some(
                          (prod) => prod?.id === product?.id
                          // (prod) => prod?.id === allProducts[indexRel]?.id
                        )
                          ? Colors?.lightgreen
                          : Colors?.white
                      }`,
                    }}
                  >
                    {checkedProducts?.some(
                      (prod) => prod?.id === product?.id
                      // (prod) => prod?.id === allProducts[indexRel]?.id
                    ) ? (
                      <span>
                        <img
                          className="wishlist"
                          src={ShortlistLightGreen}
                          alt="wishlisted"
                          size={20}
                        />
                        Shortlisted
                      </span>
                    ) : (
                      <span>
                        <img
                          className="wishlist"
                          src={ShortlistOutline}
                          alt="wishlist"
                          size={20}
                        />
                        Shortlist
                      </span>
                    )}
                  </Button>
                </Col>
                <Col span={12} align="end">
                  <Button
                    className="t5"
                    loading={modalStatus}
                    type="dark"
                    size="large"
                    disabled={quoted}
                    onClick={() => {
                      setQuoteModal(true);
                      setModalStatus(true);
                    }}
                    style={{
                      width: "98%",
                      height: "3rem",
                      color: Colors?.white,
                      border: "none",
                      background: `${
                        quoted
                          ? "linear-gradient(273.09deg, #065042 15.87%, #B6DA25 134.49%)"
                          : Colors?.black
                      }`,
                    }}
                  >
                    {quoted ? (
                      <span>
                        <img
                          className="wishlist"
                          src={quote2}
                          alt="quoted"
                          size={20}
                        />
                        Quote requested
                      </span>
                    ) : (
                      <span>
                        <img
                          className="wishlist"
                          src={quote}
                          alt="quote"
                          size={20}
                        />
                        Get quote
                      </span>
                    )}
                  </Button>
                </Col>
              </Row>

              {dismissWishlists && checkedProducts?.length > 0 && (
                <Row
                  className="chat"
                  justify="space-between"
                  style={{
                    background: Colors?.bgGray,
                  }}
                >
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Text className="t6" style={{ color: Colors?.gray1 }}>
                      Send an enquiry to know more details about your favourites
                    </Text>
                    <br></br>
                    <Row
                      className="t5 cursor"
                      style={{ paddingTop: "1rem" }}
                      onClick={() => handleQuery()}
                    >
                      View details
                      <img
                        src={arrow}
                        alt="view"
                        size={20}
                        style={{ display: "inline", paddingLeft: "0.4rem" }}
                      />
                    </Row>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} align="end">
                    <Row align="end">
                      <img
                        src={close}
                        alt="close"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => setDismissWishlists(false)}
                      />{" "}
                    </Row>
                    <Row>
                      <img
                        src={wishlists}
                        alt="wishlists"
                        size={20}
                        style={{ display: "inline" }}
                      />
                    </Row>
                  </Col>
                </Row>
              )}

              <Row
                className={`details-container ${
                  deviceType === "mobile" ? "mobileView" : "desktop"
                }`}
              >
                <Col className="details" span={24}>
                  {hingeAttributes?.map((it, index) => {
                    return (
                      <>
                        <Row className="t3 details pillsTitle">
                          {hingeWithKeyValue[it.id]}
                        </Row>
                        <Row className="t6" style={{ gap: "0.5rem" }}>
                          {pills[it.id]?.map((item) => (
                            <Button
                              className={
                                currentProductValues?.some(
                                  (obj) => obj[it.id] === item
                                )
                                  ? "selectedCataloguePills"
                                  : "cataloguePills"
                              }
                              disabled={
                                !validProductPills?.[it.id]?.includes(item)
                              }
                              // type={
                              //   currentProductValues?.some(
                              //     (obj) => obj[it.id] === item
                              //   )
                              //     ? "primary"
                              //     : "default"
                              // }
                              onClick={() => {
                                handleVariantChange(it.id, item, pillsData);
                              }}
                            >
                              {item ? item : "  "}
                            </Button>
                          ))}
                        </Row>
                      </>
                    );
                  })}
                </Col>
                <Divider style={{ marginTop: "10" }} />
                {deviceType === "mobile" ? (
                  <>
                    {/* <Col className="details" span={24}>
                      {hingeAttributes?.map((it, index) => {
                        return (
                          <>
                            <Row className="t3 details">
                              {hingeWithKeyValue[it.id]}
                            </Row>
                            <Row className="t6" style={{ gap: "0.5rem" }}>
                              {pills[it.id]?.map((item) => (
                                <Button
                                  disabled={
                                    !validProductPills?.[it.id]?.includes(item)
                                  }
                                  type={
                                    currentProductValues?.some(
                                      (obj) => obj[it.id] === item
                                    )
                                      ? "primary"
                                      : "default"
                                  }
                                  onClick={() => {
                                    handleVariantChange(it.id, item, pillsData);
                                  }}
                                >
                                  {item}
                                </Button>
                              ))}
                            </Row>
                          </>
                        );
                      })}
                    </Col> */}
                    {renderValues.slice(0, 3).map(({ key, value }, ind) => (
                      <Col className="details" span={24}>
                        <Row className="t3 details">{key.toString()}</Row>
                        {isValidUrl(value) ? (
                                <a 
                                  style={{
                                    color: '#429684'
                                  }}
                                  href={value?.toString()?.includes('https://') ? value?.toString() : `https://${value?.toString()}`} target="_blank">
                                  {value?.toString()}
                                </a>
                              ) : (
                                <Row className='t6'>{value}</Row>
                              )}
                      </Col>
                    ))}
                    {showFull &&
                      renderValues
                        .slice(
                          3,
                          renderValues.length
                          // allProducts[indexRel]?.transformedAttributes.length
                        )
                        .map(({ key, value }, ind) => (
                          <Col className="details" span={24}>
                            <Row className="t3 details">{key.toString()}</Row>
                            {isValidUrl(value) ? (
                                <a 
                                  style={{
                                    color: '#429684'
                                  }}
                                  href={value?.toString()?.includes('https://') ? value?.toString() : `https://${value?.toString()}`} target="_blank">
                                  {value?.toString()}
                                </a>
                              ) : (
                                <Row className='t6'>{value}</Row>
                              )}
                          </Col>
                        ))}
                    {renderValues.length > 3 && (
                      <span
                        style={{
                          color: "#0b7764",
                          fontSize: "12px",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowFull((_flag) => !_flag)}
                      >
                        {showFull ? "View less" : "View more"}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {
                      <>
                        {renderValues.map(({ key, value }, ind) => (
                          <>
                            <Col className="details" span={24}>
                              <Row className="t3 details">{key}</Row>
                              {isValidUrl(value) ? (
                                <a 
                                  style={{
                                    color: '#429684'
                                  }}
                                  href={value?.includes('https://') ? value : `https://${value}`} target="_blank">
                                  {value}
                                </a>
                              ) : (
                                <Row className='t6'>{value}</Row>
                              )}
                            </Col>
                          </>
                        ))}
                      </>
                    }
                  </>
                )}
              </Row>

              <MediaQuery minWidth={1200}>
                {/* {Object.entries(product?.transformedAttributes).length ===
                  0 && ( */}
                {Object.entries(allProducts[indexRel]?.transformedAttributes)
                  .length === 0 && (
                  <>
                    <Divider
                      style={{
                        marginTop: `${dismissWishlists ? "18rem" : "25rem"}`,
                      }}
                    />
                    <Text className="t6" style={{ color: Colors?.gray1 }}>
                      Scroll down to view more products
                    </Text>
                    <br></br>
                    <Button
                      className="scroll-btn"
                      onClick={() => handleScroll()}
                    >
                      <img
                        src={downArrow}
                        alt="scroll down"
                        width={10}
                        height={30}
                      />
                    </Button>
                  </>
                )}
              </MediaQuery>
            </Col>
          </Row>
          <Divider className="line" style={{ margin: "0.5rem 0" }} />
          <Row className="similar-products">
            <Text className="t2">View more products</Text>
          </Row>

          <Row className="similar-prod-cards">
            {viewProducts.map((item, ind) => {
              const getImgUrl = (product) =>
                `${process.env.REACT_APP_CLOUDINARY}/b_rgb:f2f2f2,c_lpad,h_214,q_60,w_283/${product?.product_images?.[0]?.directus_files_id}`;
              const getFallbackUrl = (product) =>
                `${process.env.REACT_APP_DIRECTUS}assets/${product?.product_images[0]?.directus_files_id}?transforms=[["resize",{"width":283,"height":214,"background":{"r":242,"g":242,"b":242}, "fit":"contain"}]]`;
              return (
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Card
                    className="cards"
                    cover={
                      <img
                        src={getImgUrl(item)}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = getFallbackUrl(item);
                        }}
                        alt="product-img"
                      />
                    }
                    onClick={() => {
                      handleCardClick(item.index);
                    }}
                    bodyStyle={{ padding: "0.5rem" }}
                  >
                    {" "}
                    <Row>
                      <Col span={24}>
                        <Text className="t5" ellipsis>
                          {item?.products_id ? `ID: ${item?.products_id}` : ""}
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <Row
            className={`explore-products ${
              deviceType === "mobile" ? "mobileView" : ""
            }`}
            align="center"
          >
            <Col>
              <Button
                className="t5"
                type="outline"
                size="large"
                style={{
                  color: Colors.gray1,
                  cursor: "pointer",
                  width: "18rem",
                  height: "3rem",
                }}
                onClick={() => handleGridView()}
              >
                <span ref={scrollRef}>Explore all products</span>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
