import React from "react";
import globalHook from "use-global-hook";
import actions from "./actions/index";
import { getItem } from "utils/localStorage";

const initialState = {
  isloggedIn: false,
  signupNew: false,
  signupError: null,
  showFilters: false,
  loading: false,
  postRequirements: null,
  error: "",
  isOnboarding: true,
  filters: {},
  products: null,
  createCollection: null,
  userCategories: null,
  collections: null,
  selectedProdutcs: [],
  buyerCollectionData: null,
  shortlistedProducts: [],
  collection_link: null,
  queriesData: null,
  editCollectionProducts: [],
  productIndex: 0,
  wishlistComments: null,
  pageSize:80,
  scrollPosition:0,
  visitorData:null,
  deviceType:localStorage.getItem("deviceType"),
  quickFilters:[],
  collectionDetails: {}
};

export const useGlobal = globalHook(React, initialState, actions);
