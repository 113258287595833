import React from "react";
import { Input, Row, Col, Typography, Form, Space } from "antd";
import ImageBox from "components/ImageBox";
const { TextArea } = Input;
const { Title, Text } = Typography;

export default function ShortListedCard({ product }) {
  console.log(product, 'product')

  return (
    <Row style={{ marginBottom: "2.5rem" }}>
      <Col
        lg={{ span: 6 }}
        xl={{ span: 6 }}
        md={{ span: 6 }}
        sm={{ span: 22 }}
        xs={{ span: 22 }}
        className="product-row"
      >
        <Space direction="vertical">
          <ImageBox
            style={{ borderRadius: "0.5rem" }}
            alt="product name"
            imgUrl={
              `${process.env.REACT_APP_CLOUDINARY}/b_rgb:#FFFFFF,c_fit,h_120,q_60,w_120/${product?.collections_products_id?.products_id?.product_images[0]?.directus_files_id}`
            }

            fallbackUrl={ product?.collections_products_id?.products_id?.product_images?.length
              ?`${process.env.REACT_APP_DIRECTUS}assets/${product?.collections_products_id?.products_id?.product_images[0]?.directus_files_id}?transforms=[["resize",{"width":120,"height":120,"fit":"contain","background":{"r":242,"g":242,"b":242}}]]`

                : "https://via.placeholder.com/120x120.png"}
          />
          <Title level={5} className="product-id" >
            {product?.collections_products_id?.products_id?.product_id ? `ID: ${product?.collections_products_id?.products_id?.product_id}` : ""}
          </Title>
        </Space>
      </Col>
      {/* <Col
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        lg={{ span: 6 }}
        xl={{ span: 6 }}
        md={{ span: 6 }}
        sm={{ span: 22 }}
        xs={{ span: 22 }}
        className="product-row"
      >
        <Row align="middle">



        </Row>


      </Col> */}
      <Col
        lg={{ span: 18 }}
        xl={{ span: 18 }}
        md={{ span: 18 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
        className="product-row"
      >

        <Form.Item name={[`${product?.collections_products_id?.id}`, "comment"]}>
          <TextArea
            disabled
            defaultValue={product?.comment} rows={5} placeholder="No comments added for this product" />
        </Form.Item>
      </Col>
    </Row>
  );
}
