import React, { useState, useEffect } from "react";

import { Typography, Row, Col, Card, Space, Image, Button } from "antd";
import Pill3D from "./3DPill.svg";
import { useParams, useHistory } from "react-router-dom";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";
import { useGlobal } from "store/index";
import { Shimmer } from "../../../components/Shimmer/index";

import ShortlistOutline from "../../../assets/images/ShortlistOutline.svg";
import ShortlistGreen from "../../../assets/images/ShortlistGreen.svg";
import { useLocation } from "react-router-dom";

import { QuoteModal, WishlistModal } from "../../../routes/SliderView/Modals";

const { Title, Text } = Typography;

const ThumbnailQuality = localStorage.getItem("catalogueThumbnailQuality");

const getImgUrl = (directus_files_id) => {
  let _url = `${process.env.REACT_APP_CLOUDINARY}/b_rgb:ffffff,c_lpad,h_${
    ThumbnailQuality === "high" ? "428" : "214"
  },q_${ThumbnailQuality === "high" ? "100," : "60,"}w_${
    ThumbnailQuality === "high" ? "566" : "283"
  }/${directus_files_id}`;

  return `${process.env.REACT_APP_CLOUDINARY}/b_rgb:ffffff,c_lpad,h_${
    ThumbnailQuality === "high" ? "428" : "214"
  },q_${ThumbnailQuality === "high" ? "100," : "60,"}w_${
    ThumbnailQuality === "high" ? "566" : "283"
  }/${directus_files_id}`;
};

const getFallbackUrl = (directus_files_id) => {
  let _url = `${
    process.env.REACT_APP_DIRECTUS
  }assets/${directus_files_id}?transforms=[["resize",{"width":${
    ThumbnailQuality === "high" ? "566" : "283"
  },"height":${
    ThumbnailQuality === "high" ? "428" : "214"
  },"fit":"contain","background":{"r":255,"g":255,"b":255}}]]`;

  return `${
    process.env.REACT_APP_DIRECTUS
  }assets/${directus_files_id}?transforms=[["resize",{"width":${
    ThumbnailQuality === "high" ? "566" : "283"
  },"height":${
    ThumbnailQuality === "high" ? "428" : "214"
  },"fit":"contain","background":{"r":255,"g":255,"b":255}}]]`;
};

const findPriceAttribute = (transformedAttributes, keyword) => {
  var attributes = Object.keys(transformedAttributes);
  var index = attributes.findIndex((element) =>
    element.toLowerCase().includes(keyword)
  );
  if (index !== -1) {
    return transformedAttributes?.[attributes[index]]?.split(" ") || [];
  } else {
    return [];
  }
};
const getPrice = (transformedAttributes) => {
  var price = findPriceAttribute(transformedAttributes, "price");
  if (price.length > 2) {
    return price;
  } else {
    return findPriceAttribute(transformedAttributes, "cost");
  }
};

function ProductCard({
  product,
  index,
  handleShortlist,
  isShortlisted,
  nextProduct,
  prevProduct,
  allHits,
  handleNext,
  handlePrev,
  mobileCols,
  id,
}) {
  const [imgSrc, setImgSrc] = useState(
    product?.product_images?.[0]
      ? getImgUrl(product.product_images[0].directus_files_id)
      : "http://placehold.jp/24/0a8f8d/ffffff/283x214.png?text=Product Image"
  );
  const [fallbackImgSrc, setFallbackImgSrc] = useState(
    getFallbackUrl(product.product_images[0].directus_files_id)
  );
  const [isHover, setIsHover] = useState(false);
  const [quoted, setQuoted] = useState(false);
  const [quoteModal, setQuoteModal] = useState(false);

  const history = useHistory();
  const params = useParams();
  const searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString);
  const collection_link = params?.id || window?.name;
  const [globalState, setGlobalState] = useGlobal();
  const [modalStatus, setModalStatus] = useState(false);
  const location = useLocation();

  const [imageLoaded, setImageLoaded] = useState(false);

  const deviceType = globalState.deviceType;

  useEffect(() => {
    if (isHover) {
      setTimeout(() => {
        // if(isHover) {
        if (product.product_images.length > 1) {
          setImgSrc(getImgUrl(product.product_images[1].directus_files_id));
          setFallbackImgSrc(
            getFallbackUrl(product.product_images[1].directus_files_id)
          );
        }
        // }
      }, 1000);
    } else {
      setImgSrc(getImgUrl(product.product_images[0].directus_files_id));
      setFallbackImgSrc(
        getFallbackUrl(product.product_images[0].directus_files_id)
      );
    }
  }, [isHover, imgSrc, fallbackImgSrc]);

  const handleCardClick = () => {
    history.push({
      pathname: params?.expname
        ? `/slider-view/${params?.expname}/${collection_link}`
        : `/slider-view/${collection_link}`,
      search: urlParams.toString(),
    });
    sendAnalyticsEvent("show_product_details", {
      collection_link: collection_link,
    });
    setGlobalState.products.setProductIndex(index);
    // setGlobalState.scrollPosition.onChangeScroll(scrollPosition);
  };

  const updateImgSrc = () => {
    setIsHover((state) => true);
  };

  const resetImgSrc = () => {
    setIsHover((state) => false);
  };

  const handleQuoteClick = (e) => {
    sendAnalyticsEvent("buyer_side_get_quote_main_page", {
      name: "buyer_side_get_quote_main_page",
      modules: "Product card ",
      location: location.pathname,
      // property: 'create quotation',
    });
    e.stopPropagation();
    setModalStatus(true);
    setQuoteModal(true);
  };

  return (
    <Col
      onMouseOver={updateImgSrc}
      onMouseOut={resetImgSrc}
      key={index}
      xs={mobileCols === 2 ? 12 : 24}
      sm={mobileCols === 2 ? 12 : 24}
      md={12}
      lg={6}
      xl={6}
      className="productCard"
      id={`product-card-${index}`}
    >
      <Card
        className={`product-card ${mobileCols === 2 ? "dualCols" : ""}`}
        id="productCard"
        cover={
          <div className="cardCover">
            {/* <MediaQuery minWidth={600}> */}
            {deviceType !== "mobile" && imageLoaded && (
              <div className="hoverDiv">
                {isShortlisted ? (
                  <div
                    className="wishlistAction"
                    onClick={() => handleShortlist(product, !isShortlisted)}
                  >
                    <span className="shortlist hover">
                      <img
                        src={ShortlistGreen}
                        width="60%"
                        style={{ borderRadius: "4px" }}
                        alt="Shortlist"
                      />
                    </span>
                    Shortlisted
                  </div>
                ) : (
                  <div
                    className="wishlistAction"
                    onClick={() => handleShortlist(product, !isShortlisted)}
                  >
                    <span className="shortlist hover" data-tour={`${index}`}>
                      <img
                        src={ShortlistOutline}
                        width="60%"
                        // height="20%"
                        // style={{ borderRadius: "25px" }}
                        alt="Shortlist"
                      />
                    </span>
                    Shortlist
                  </div>
                )}
              </div>
            )}
            {/* </MediaQuery> */}

            <>
              {/* <MediaQuery minWidth={600}> */}
              {deviceType !== "mobile" && (
                <>
                  {isShortlisted && (
                    <span
                      // onClick={() => handleShortlist(product, !isShortlisted)}
                      className="shortlist boxShadow"
                    >
                      <img
                        src={ShortlistGreen}
                        width="80%"
                        style={{ borderRadius: "4px" }}
                        alt="Shortlist"
                      />
                    </span>
                  )}
                </>
              )}
              {/* </MediaQuery> */}
              {/* <MediaQuery maxWidth={600}> */}
              {deviceType === "mobile" && (
                <>
                  {isShortlisted ? (
                    <div
                      className={
                        mobileCols === 2 ? "shortlistCol" : "shortlist"
                      }
                    >
                      <span
                        onClick={() => handleShortlist(product, !isShortlisted)}
                      >
                        <img
                          src={ShortlistGreen}
                          width="80%"
                          style={{ borderRadius: "4px" }}
                          alt="Shortlist"
                        />
                      </span>
                    </div>
                  ) : (
                    <div
                      className={
                        mobileCols === 2 ? "shortlistCol" : "shortlist"
                      }
                    >
                      <span
                        onClick={() => handleShortlist(product, !isShortlisted)}
                      >
                        <img
                          src={ShortlistOutline}
                          width={`${mobileCols === 2 ? "75%" : "80%"}`}
                          style={{ borderRadius: "4px" }}
                          alt="Shortlist"
                        />
                      </span>
                    </div>
                  )}
                </>
              )}
              {/* </MediaQuery> */}

              <Image
                fallback={fallbackImgSrc}
                src={imgSrc}
                style={imageLoaded ? {} : { display: "none" }}
                preview={false}
                onLoad={() => {
                  setImageLoaded(true);
                }}
                onClick={() => {
                  handleCardClick();
                }}
              />
              <div>
                <Shimmer
                  style={{
                    display: `${imageLoaded ? "none" : "block"}`,
                    // background: 'red',
                    height: `${deviceType === "mobile" ? "10rem" : "14rem"}`,
                    width: "100%",
                  }}
                />
              </div>
            </>
          </div>
        }
        bodyStyle={{ borderTop: "1px solid #E6E7E7" }}
      >
        <div>
          {product?.has_360_view && (
            <img
              src={Pill3D}
              alt="3D Pill"
              style={{ top: 0, position: "absolute", marginTop: 12, border: 0 }}
            />
          )}
          <Row
            className="productDetail"
            style={{ minHeight: "3rem" }}
            onClick={(e) => {
              e.preventDefault();
              handleCardClick();
            }}
          >
            <Space direction="vertical">
              <Row>
                <Title
                  ellipsis
                  className={`product-id ${
                    deviceType === "mobile" ? "textEllipsis" : ""
                  } ${mobileCols === 2 ? "gridSmallText" : ""}`}
                  style={{ fontWeight: "bolder" }}
                  level={5}
                >
                  {product?.products_id ? `ID - ${product?.products_id}` : ""}
                </Title>
                {/* <MediaQuery minWidth={600}> */}
                {deviceType !== "mobile" && imageLoaded && (
                  <Button
                    className="getQuote"
                    loading={modalStatus}
                    disabled={quoted}
                    onClick={handleQuoteClick}
                  >
                    Get Quote
                  </Button>
                )}
              </Row>
              {product?.variant_meta?.variant_data_map?.length > 1 ? (
                <Row style={{ marginTop: "-8px" }}>
                  {/* <Space direction='horizontal' size='small'> */}
                  <Text type="secondary" className="variants">
                    {product?.variant_meta?.variant_data_map?.length +
                      " OPTIONS"}
                  </Text>
                  {/* </Space> */}
                </Row>
              ) : null}
            </Space>
          </Row>
        </div>
      </Card>
      <QuoteModal
        quoteModal={quoteModal}
        setQuoteModal={setQuoteModal}
        setQuoted={setQuoted}
        product={product}
        id={id}
        setModalStatus={setModalStatus}
      />
    </Col>
  );
}

export default React.memo(ProductCard);
