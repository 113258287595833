import ImageRouter from 'react-common-components/ImageRouter';
import { sendAnalyticsEvent } from 'utils/sendAnalyticsEvent';

function ArrowPrev(clickHandler, hasPrev, label) {
  return (
    <button
      onClick={() => {
        clickHandler();
        sendAnalyticsEvent('slide-left', {});
      }}
      className={`${
        hasPrev ? '' : 'opacity-25'
      } left-4 bottom-10 justify-end h-max md:left-auto md:right-32 flex flex-col items-center md:justify-center pb-2 md:pb-0 absolute md:top-auto md:bottom-14 md:right-32 rounded-full md:w-10 md:h-10 md:flex md:items-center md:justify-center md:bg-[#1D2624] md:text-white`}
    >
      <svg
        className='rounded-full w-10 h-10 flex items-center justify-center bg-[#1D2624] backdrop-blur-[1.5px] backdrop-filter text-white'
        width='25'
        height='26'
        viewBox='0 0 25 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.2099 18.29C14.8199 18.68 14.1899 18.68 13.7999 18.29L9.20993 13.7C8.81993 13.31 8.81993 12.68 9.20993 12.29L13.7999 7.7C14.1899 7.31 14.8199 7.31 15.2099 7.7C15.5999 8.09 15.5999 8.72 15.2099 9.11L11.3299 13L15.2099 16.88C15.5999 17.27 15.5899 17.91 15.2099 18.29Z'
          fill='currentColor'
        />
      </svg>
    </button>
  );
}
function Indicators(clickHandler, isSelected, index, label) {
  return (
    <button
      onClick={clickHandler}
      className={`${
        isSelected ? 'bg-[#4F5655] p-[6px] md:p-2' : 'bg-[#C4C4C4]'
      } z-50 rounded-full w-2 h-2 md:w-3 md:h-3 mt- md:mt-0 mx-1 md:mx-2 transition-all ease-in-out duration-300`}
    ></button>
  );
}
function ArrowNext(clickHandler, hasNext, label) {
  return (
    <button
      onClick={() => {
        clickHandler();
        sendAnalyticsEvent('slide-right', {});
      }}
      className={`${
        hasNext ? '' : 'opacity-25'
      } right-4 xyz justify-end h-max md:left-auto md:right-16 bottom-10 flex flex-col items-center md:justify-center pb-2 md:pb-0 absolute md:top-auto md:bottom-14 rounded-full md:w-10 md:h-10 md:flex md:items-center md:justify-center md:bg-[#1D2624] md:text-white`}
      // style={{ marginTop: '37rem' }}
    >
      <svg
        className='rounded-full w-10 h-10 flex items-center justify-center bg-[#1D2624] backdrop-blur-[1.5px] backdrop-filter text-white'
        width='25'
        height='26'
        viewBox='0 0 25 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.79006 18.29C10.1801 18.68 10.8101 18.68 11.2001 18.29L15.7901 13.7C16.1801 13.31 16.1801 12.68 15.7901 12.29L11.2001 7.7C10.8101 7.31 10.1801 7.31 9.79006 7.7C9.40006 8.09 9.40006 8.72 9.79006 9.11L13.6701 13L9.79006 16.88C9.40006 17.27 9.41006 17.91 9.79006 18.29Z'
          fill='currentColor'
        />
      </svg>
    </button>
  );
}

function ImageArrowPrev(clickHandler, hasPrev, label) {
  return (
    <button
      onClick={() => {
        clickHandler();
      }}
      className={`${
        hasPrev ? '' : 'hidden'
      } absolute left-2 md:left-5 bottom-0 top-0 flex flex-col items-center justify-center h-full `}
    >
      <svg
        className='rounded-full w-7 h-7 flex items-center justify-center bg-[#1D2624]/60 backdrop-blur-[1.5px] backdrop-filter text-white'
        width='25'
        height='26'
        viewBox='0 0 25 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.2099 18.29C14.8199 18.68 14.1899 18.68 13.7999 18.29L9.20993 13.7C8.81993 13.31 8.81993 12.68 9.20993 12.29L13.7999 7.7C14.1899 7.31 14.8199 7.31 15.2099 7.7C15.5999 8.09 15.5999 8.72 15.2099 9.11L11.3299 13L15.2099 16.88C15.5999 17.27 15.5899 17.91 15.2099 18.29Z'
          fill='currentColor'
        />
      </svg>
    </button>
  );
}
function ImageArrowNext(clickHandler, hasNext, label) {
  return (
    <button
      onClick={() => {
        clickHandler();
      }}
      className={`${
        hasNext ? '' : 'hidden'
      } absolute right-2 md:right-5 bottom-0 top-0 flex flex-col items-center justify-center h-full`}
    >
      <svg
        className='rounded-full w-7 h-7 flex items-center justify-center bg-[#1D2624]/60 backdrop-blur-[1.5px] backdrop-filter text-white'
        width='25'
        height='26'
        viewBox='0 0 25 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.79006 18.29C10.1801 18.68 10.8101 18.68 11.2001 18.29L15.7901 13.7C16.1801 13.31 16.1801 12.68 15.7901 12.29L11.2001 7.7C10.8101 7.31 10.1801 7.31 9.79006 7.7C9.40006 8.09 9.40006 8.72 9.79006 9.11L13.6701 13L9.79006 16.88C9.40006 17.27 9.41006 17.91 9.79006 18.29Z'
          fill='currentColor'
        />
      </svg>
    </button>
  );
}
const customRenderItem = (item, props) => (
  <item.type {...item.props} {...props} />
);

const customRenderThumb = (children) => {
  let image = children.map((item) => {
    let imageId;
    let fallbackImageId;
    imageId = item.props.src;
    fallbackImageId = item.props.fallbackUrl;
    return (
      <ImageRouter src={imageId} fallback={fallbackImageId} preview={false} />
    );
  });
  return image;
};

const renderAttributeData = (data, type) => {
  switch (type) {
    case 'price':
      return `${data?.composite?.currency || ''} ${data?.values || ''} ${data?.composite?.unit || ''}`
    default:
      return `${data?.values || ''}`
  }
}

export {
  ArrowPrev,
  Indicators,
  ArrowNext,
  ImageArrowPrev,
  ImageArrowNext,
  customRenderItem,
  customRenderThumb,
  renderAttributeData
};
